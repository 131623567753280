import { useCallback, useEffect, useState } from "react";
import { Col, Row, Modal, Spin, Form, message, Space } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import { useAppContext } from "context/appContext";
import { createApps, deleteApp, getApps } from "api/proxy/admin-api";

import { Button, Input, Select } from "components/form";
import Icon from "components/icon";
import { Card } from "components/cards";
import Section from "components/section";
import DocsInfo from "components/docsInfo";

import Styles from "./styles/home.module.scss";
import KnowledgeAICardImage from "assets/images/img/knowledgemanagement-card.png";
import ProcurmentAICardImage from "assets/images/img/procurement-card.png";

const { confirm } = Modal;

const Home = () => {
  const [form] = Form.useForm();
  const { meData } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [appList, setAppList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [appsContext, setAppsContext] = useState({});

  const getAllApps = useCallback(() => {
    getApps(
      { admin: meData?.org_admin || false, user: meData?.user },
      (apps) => {
        if (apps) {
          let dummyObject = {};
          apps.forEach((app) => {
            dummyObject[app.uid] = app.direct;
          });
          setAppsContext(dummyObject);
          setAppList(apps);
          setLoading(false);
        }
      }
    );
  }, [meData]);

  useEffect(() => {
    setLoading(true);
    let interval;

    if (meData?.user) {
      getAllApps();
      interval = setInterval(() => {
        getAllApps();
      }, 2000);
    }

    return () => interval && clearInterval(interval);
  }, [meData?.org_admin, meData?.user, getAllApps]);

  const handleDelete = (id, name) => {
    confirm({
      title: `Do you want to delete these app?`,
      icon: <ExclamationCircleFilled />,
      centered: true,
      onOk() {
        deleteApp({ uid: id, name }, (msg) => {
          setAppList((prev) => {
            return prev.filter((item) => item.uid !== id);
          });
        });
      },
      onCancel() {},
    });
  };

  const handleCreateAppClick = () => {
    setIsModalOpen(true);
  };

  const handleFormSubmit = (data) => {
    setIsLoadingSubmit(true);
    createApps(
      { creator: meData?.user, name: data.name, type: data.type },
      (msg, error) => {
        setIsLoadingSubmit(false);
        if (error) {
          message.open({
            type: "error",
            content: error,
          });
        } else {
          getAllApps();
          setIsModalOpen(false);
          form.resetFields();
        }
      }
    );
  };

  return (
    <div className={Styles.wrapper}>
      <div className={Styles["apps-area"]}>
        <Section
          heading={
            <>
              <b>MY</b> APPS
            </>
          }
        >
          {loading ? (
            <Spin />
          ) : (
            <Row gutter={[70, 70]}>
              {appList.map((item) => (
                <Col xl={6} lg={12} sm={12} key={item.uid}>
                  <Card
                    heading={item.name}
                    className={Styles["card-wrapper"]}
                    onDelete={handleDelete}
                    item={item}
                    isAdmin={meData?.org_admin}
                    appsContext={appsContext}
                    coverHeight={139}
                    cover={
                      item.app_image
                        ? item.app_image
                        : item.type === "procurement_ai"
                        ? ProcurmentAICardImage
                        : KnowledgeAICardImage
                    }
                  />
                </Col>
              ))}
              {meData?.org_admin && (
                <Col xl={6} lg={12} sm={12}>
                  <Button
                    type="primary"
                    className={Styles["create-button"]}
                    onClick={handleCreateAppClick}
                  >
                    <Space direction="vertical" className={Styles.space}>
                      <AddCircleOutlineOutlinedIcon
                        className={Styles["add-icon"]}
                      />
                      Create a new app
                    </Space>
                  </Button>
                </Col>
              )}
            </Row>
          )}
        </Section>
      </div>
      {/* <div>
        <DocsInfo />
      </div> */}

      <Modal
        title="Create App"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[]}
        destroyOnClose
        centered
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handleFormSubmit}
          // initialValues={{ type: "test" }}
          requiredMark={false}
        >
          <Form.Item
            label="App Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter app name",
              },
            ]}
          >
            <Input placeholder="Enter App Name" />
          </Form.Item>
          <Form.Item
            label="App Type"
            name="type"
            rules={[
              {
                required: true,
                message: "Please select type",
              },
            ]}
          >
            <Select
              placeholder="Select Type"
              options={[
                {
                  label: "Knowledge AI",
                  value: "knowledge_ai",
                },
                {
                  label: "Procurement AI",
                  value: "procurement_ai",
                },
                {
                  label: "Talent AI - Skills",
                  value: "skills_ai",
                },
              ]}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ paddingBlock: 9 }}
              loading={isLoadingSubmit}
            >
              CREATE
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Home;
